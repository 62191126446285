/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  less dependencies when defined in the bower.json of your dependencies
 */
// bower:less
// endbower

/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap/less/variables.less
 */
@navbar-inverse-link-color: #5AADBB;
@icon-font-path: '/styles/fonts/';

@font-face {
    font-family: Clip;
    src: url(/assets/fonts/clip.regular.ttf);
}

// Define Colour variables.
@orange: #fc7c00;
@yellow: #ffff36;
@blue: #40ffed;
@red: #F60109;
@pink: #FF00AA;
@green: #00FF00;
@white: #bababa;

@background-colour: black;

html, body{
  height: 100%;
}

body {
  background-color: @background-colour;
  background-image: url("/assets/images/blue-bk.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

h1 {
  font-size: 55px;
}

h2 {
  font-size: 38px;
}

a {
  color: white;
  cursor: pointer;
}

a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}

.orange {
  color: @orange !important;
  border-color: @orange !important;
}

.pink {
  color: @pink !important;
  border-color: @pink !important;
}

.blue {
  color: @blue !important;
  border-color: @blue !important;
}

.green {
  color: @green !important;
  border-color: @green !important;
}

.red {
  color: @red !important;
  border-color: @red !important;
}

.yellow {
  color: @yellow !important;
  border-color: @yellow !important;
}

.white {
  color: @white !important;
  border-color: @white !important;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.neon-font {
  font-family: Clip;
}

.neon-homepage {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.thumbnail {
  height: 200px;
  img.pull-right {
    width: 50px;
  }
}

.carousel-control.left, .carousel-control.right {
    background-image: none
}

.row-centered {
    text-align:center;
}

footer {
  color: @blue;
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 10pt;
}

footer a {
  color: dark-grey;
}

.twitter {
  margin-right: 140px;
}

.divider {
  padding-left: 10px;
  padding-right: 10px;
}

.border-glow {
  padding: 5px;
  margin: 15px;
  border: 3px solid @orange;
  outline:none;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  /* create a BIG glow */
  box-shadow: 0px 0px 48px;
  -moz-box-shadow: 0px 0px 48px;
  -webkit-box-shadow: 0px 0px 48px;
  color: @orange;
}

/**************************
  Outline Buttons with glow
**************************/
.btn {
  min-width: 9em;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  // background-image: url("/assets/images/button.jpg");
  background: -webkit-linear-gradient(top,  #2c354e 0%,#576a97 100%);
	background: -moz-linear-gradient(top,  #2c354e 0%,#576a97 100%);
  // background: black;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 20px 16px;
  padding: 14px 24px;
  border: 0 none;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  outline:none;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  /* create a BIG glow */
  box-shadow: 0px 0px 52px;
  -moz-box-shadow: 0px 0px 52px;
  -webkit-box-shadow: 0px 0px 52px;
  color: @orange;
}

.btn-lg {
  font-size: 18px;
  line-height: 1.33;
  border-radius: 8px;
}

.btn.outline {
    padding: 12px 22px;
}

.btn-primary.outline {
    border: 2px solid @orange;
    color: @orange;
}

.btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0 none;
}

// Modal Styling
.modal-content {
  background-color: @background-colour;
  border: 1px solid @blue;
}

.modal-footer, .modal-header  {
  border: none;
}

.modal-body {
  border-top: 1px;
  border-bottom: 1px;
  border-left: 0px;
  border-right: 0px;
  border-style: solid;
  border-color: @blue;
  color: white;
}

.modal-backdrop {
    opacity:0.8 !important;
}

.text-danger {
  font-size: 16pt;
  color: @red;
  padding-bottom: 15px;
}

.spacer {
  margin-top: 20px;
}

.spacer-2x {
  margin-top: 60px;
}

.spacer-3x {
  margin-top: 60px;
}

.spacer-4x {
  margin-top: 80px;
}

.spacer-5x {
  margin-top: 100px;
}

/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your less files automatically
 */
// injector
// endinjector
